import React, { useState } from 'react';
import axios from 'axios';
import { ImSpinner, ImHammer2 } from "react-icons/im";
import { RiSendPlaneFill } from "react-icons/ri";
import toast, { Toaster } from 'react-hot-toast';

const Player = () => {
  const [playerName, setPlayerName] = useState('');
  const [playerData, setPlayerData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [punishments, setPunishments] = useState([]);
  const [punishmentsLoading, setPunishmentsLoading] = useState(false);
  const [punishmentsFetched, setPunishmentsFetched] = useState(false);
  const [punishmentsError, setPunishmentsError] = useState('');
  const [selectedPunishment, setSelectedPunishment] = useState(null);
  const [showAltPunishments, setShowAltPunishments] = useState(false);

  const searchPlayer = async () => {
    setLoading(true);
    setError('');
    setPlayerData(null);
    setPunishments([]);
    setPunishmentsFetched(false);
    setShowAltPunishments(false);

    try {
      const response = await fetch(`https://api.ngmc.co/v1/players/${playerName}?period=global&withReportCount=true&withUsernameHistory=true&withWinStreaks=true`);
      if (!response.ok) {
        throw new Error(`${response.status}: Cannot find ${playerName}`);
      }

      const data = await response.json();

      if (!data || !data.name) {
        throw new Error('Invalid data structure received.');
      }

      setPlayerData(data);
      sendToDiscordWebhook(data.name);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPunishments = async () => {
    setPunishmentsLoading(true);
    setPunishmentsError('');

    try {
      const response = await axios.get(`https://api.ngmc.co/v1/players/${playerData.xuid}/punishments`);
      setPunishments(response.data.punishments);
      if (response.data.punishments.length === 0) {
        toast.success('No punishments found for this player :)');
      }
      setPunishmentsFetched(true);
    } catch (error) {
      setPunishmentsError('Failed to fetch punishments.');
    } finally {
      setPunishmentsLoading(false);
    }
  };

  const sendToDiscordWebhook = (playerName) => {
    const webhookURL = 'https://discord.com/api/webhooks/1213847581889663018/FJu4a86d_-CHTwW2ylL3g8ZlUaurUjXLLxIh25_6w8fysQkLR785n6xdCHpw9dmaM1WW';
    const payload = {
      content: `Player ${playerName} searched successfully!`
    };

    fetch(webhookURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to send message to Discord webhook. Status: ${response.status}`);
        }
      })
      .catch(error => console.error('Error sending message to Discord webhook:', error));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchPlayer();
    }
  };

  const getBackgroundColor = (label) => {
    switch (label.toLowerCase()) {
      case 'admin':
        return '#D6342A';
      case 'titan':
        return '#B91C1D';
      case 'ultra':
        return '#DD9833'; // Hex color for 'gold'
      case 'emerald':
        return '#40BA4B';
      case 'legend':
        return '#02D3F1';
      case 'crew':
        return '#86F103';
      case 'trainee':
        return '#F1C40F';
      case 'banned':
        return 'E53E3E';
      case 'muted':
        return '#F8E15F';
      case 'tester':
        return '#01AA01';
      case 'mod':
        return '#3497DA'; // Hex color for 'cyan'
      case 'media':
        return '#127F6A'; // Hex color for 'gold'
      case 'supervisor':
        return '#E258C0'; // Hex color for 'gold'
      case 'game designer':
        return '#BF7B10'; // Hex color for 'gold'
      case 'partner':
        return '#DF6A00'; // Hex color for 'gold'
      default:
        return 'bg-zinc-400'; // Default background color
    }
  };

  const getInlineStyle = (label) => {
    const backgroundColor = getBackgroundColor(label);
    return backgroundColor.startsWith('#') ? { backgroundColor } : {};
  };

  const processMarkdownSyntax = (text) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/__(.*?)__/g, '<em>$1</em>')
      .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" style="color: orange;">$1</a>')
      .replace(/\n/g, '<br/>');
  };

  const handlePunishmentClick = (punishment) => {
    setSelectedPunishment(punishment);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-900 text-white p-4">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">Search Player</h2>
        <div className="flex justify-center mb-4">
          <input
            type="text"
            className="input input-bordered font-bold w-full max-w-md"
            placeholder="Enter player name"
            style={{ color: 'white', backgroundColor: '#000' }}
            value={playerName}
            minLength="1"
            maxLength="16"
            reqired
            onChange={(e) => setPlayerName(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="btn btn-primary btn-outline ml-2" onClick={searchPlayer} disabled={loading}>
            {loading ? (
              <span>Search <ImSpinner className="animate-spin ml-2 inline-block " /></span>
            ) : <span>Search <RiSendPlaneFill className="inline-block ml-2" /></span>}
          </button>
        </div>
        {error && <div className="alert alert-warning text-center">{error}</div>}
        {playerData && (
          <div className="card bg-gray-800 shadow-lg mt-4">
            <div className="card-body">
              <div className="flex flex-col items-center mb-4">
                <img
                  src={`${playerData.avatar}`} // Assuming the API provides an avatar endpoint
                  alt={`${playerData.name}'`}
                  className="rounded-full mb-4 border border-gray-300"
                  style={{ width: '100px', height: '100px' }}
                />
                <h1 className="text-4xl font-bold mb-2">{playerData.name}</h1>
                <div className="flex flex-wrap justify-center mb-4">
                  {playerData.ranks && (
                    (Array.isArray(playerData.ranks) ? playerData.ranks : playerData.ranks.split(',')).map((rank, index) => (
                      <span
                        key={index}
                        className={`text-white px-2 font-bold text-xs shadow-black rounded-full m-1 ${getBackgroundColor(rank).startsWith('bg-') ? getBackgroundColor(rank) : ''}`}
                        style={{
                          ...getInlineStyle(rank),
                          textShadow: '1px 1px 2px black',
                        }}
                      >
                        {rank.toUpperCase()}
                      </span>
                    ))
                  )}
                  {playerData.banned && (
                    <span className="bg-red-500 text-xs shadow-black text-white px-2 rounded-full font-bold m-1"
                      style={{ textShadow: '1px 1px 2px black' }}
                    >
                      BANNED
                    </span>
                  )}
                  {playerData.muted && (
                    <span className="bg-yellow-500 text-xs shadow-black text-black px-2 rounded-full font-bold m-1"
                      style={{ textShadow: '1px 1px 2px black' }}
                    >
                      MUTED
                    </span>
                  )}
                </div>
              </div>
              <hr className="border-gray-600" />
              <p className="mb-2 overflow-x-auto"><strong></strong> <span dangerouslySetInnerHTML={{ __html: processMarkdownSyntax(playerData.bio || '') }} /></p>
              <hr className="border-gray-600" />
              <p><strong>Level:</strong> {playerData.level || 0}</p>
              <p><strong>Discord:</strong> {playerData.discordTag || 'N/A'}</p>
              <p><strong>Guild:</strong> {playerData.guild || 'N/A'}</p>
              <p><strong>Tier:</strong> {playerData.tier || 'N/A'}</p>
              <p><strong>Wins:</strong> {playerData.wins || 0}</p>
              <p><strong>Losses:</strong> {playerData.losses || 0}</p>
              <p><strong>Kills:</strong> {playerData.kills || 0}</p>
              <p><strong>Deaths:</strong> {playerData.deaths || 0}</p>
              <p><strong>Reports Submitted:</strong> 
                 <span className="font-bold texy-red-900 ml-2">{playerData.reportCount || 0}</span></p>
              {playerData.usernameHistory && Array.isArray(playerData.usernameHistory) && playerData.usernameHistory.length > 0 && (
                <div>
                  <h4 className="text-lg font-bold">Previously Known As</h4>
                  <ul className="list-disc list-inside">
                    {playerData.usernameHistory.map((name, index) => (
                      <li key={index} className="text-gray-300">{name}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="flex justify-center mt-4">
                <button className="btn btn-primary btn-outline" onClick={fetchPunishments} disabled={punishmentsLoading}>
                  {punishmentsLoading ? <span>View Punishments <ImSpinner className="animate-spin ml-2 inline-block" /></span> : <span>View Punishments <ImHammer2 className="ml-2 inline-block" /></span>}
                </button>
                <Toaster />
              </div>
            </div>
          </div>
        )}
        {punishmentsError && <div className="alert alert-warning text-center mt-4">{punishmentsError}</div>}
        {punishmentsFetched && !punishmentsLoading && punishments.length === 0 && (
          <div className="alert alert-info text-center mt-4">This player has no punishments :)</div>
        )}
        {punishmentsFetched && punishments.length > 0 && (
          <div className="space-y-4 mt-4">
            <div className="flex justify-center mb-4">
              <button
                className={`btn ${showAltPunishments ? 'btn-secondary btn-outline no-animation' : 'btn-secondary btn-outline'}`}
                onClick={() => setShowAltPunishments(!showAltPunishments)}
              >
                {showAltPunishments ? 'Hide' : 'Show'} Alt Punishments
              </button>
            </div>
            {punishments
              .filter(punishment => !punishment.alt || showAltPunishments)
              .map((punishment) => (
                <div
                  key={punishment.id}
                  className="card bg-gray-900 p-4 rounded-lg shadow-lg mb-4 cursor-pointer transition-transform transform hover:scale-103"
                  onClick={() => handlePunishmentClick(punishment)}
                >
                  <div className="card-body">
                    <h2 className="card-title">
                      {punishment.type === 'BAN' ? 'Ban' : 'Mute'} {punishment.active ? 'ACTIVE' : 'EXPIRED'}
                    </h2>
                    <p>
                      {punishment.permanent
                        ? 'Permanent'
                        : new Date(punishment.validUntil * 1000).toLocaleString('en-US', {
                            timeZone: 'Asia/Kolkata',
                            timeZoneName: 'short',
                          })}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        )}

        {selectedPunishment && (
          <div className="modal modal-open">
            <div className="modal-box overflow-x-auto">
              <h3 className="font-bold text-lg gap-y-4">Punishment Details</h3>
              <p>ID: {selectedPunishment.id}</p>
              <p>REASON: {selectedPunishment.reason}</p>
              <p>VAILD UNTIL: {new Date(selectedPunishment.validUntil * 1000).toLocaleString('en-US', {
                  timeZone: 'Asia/Kolkata',
                  timeZoneName: 'short',
                })}</p>
              <p>ISSUED AT: {new Date(selectedPunishment.issuedAt * 1000).toLocaleString('en-US', {
                  timeZone: 'Asia/Kolkata',
                  timeZoneName: 'short',
                })} </p>
              <p>ACTIVE: {selectedPunishment.active ? 'Yes' : 'No'}</p>
              <p>ALT PUNISHMENT: {selectedPunishment.alt ? 'Yes' : 'No'}</p>
              <p>Affected Players: {selectedPunishment.affectedPlayers.join(', ')}</p>
              <div className="modal-action">
                <button className="btn" onClick={() => setSelectedPunishment(null)}>CLOSE</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Player; 